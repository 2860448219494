import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { laresPoints, rankPrizes } from './utils'

const LoyaltyInfo = () => {
    const { t } = useTranslation()
    const levelMultiplier = [
        { level: 'specialist', value: '2.5', label: 'levelSpecialist' },
        { level: 'gold', value: '2', label: 'levelGold' },
        { level: 'silver', value: '1.5', label: 'levelSilver' },
        { level: 'selected', value: '1', label: 'levelSelected' }
    ]

    // TODO: Temporary remove
    const mustRemove = new Date().getUTCFullYear().toString() === '2025'
    return (
        <>
            <h2 className="title-page">
                {t('loyaltyInfo')}
            </h2>
            <div className="page-container">
                <div className='content-block'>
                    <h3 className="content-h3">{t('loyaltyInfo1Title')}</h3>
                    <p className='content-p'>{t('loyaltyInfo1Text')}</p>
                </div>
                <div className='content-block'>
                    <h3 className="content-h3">{t('loyaltyInfo2Title')}</h3>
                    <p className='content-p'>{t('loyaltyInfo2Text')}</p>
                </div>
                <div className='content-block'>
                    <h3 className="content-h3">{t('loyaltyInfo3Title')}</h3>
                    <p className='content-p'>{t('loyaltyInfo3Text')}</p>
                    <ul className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4'>
                        {levelMultiplier.map((item, i) => (
                            <li key={item.value} className={clsx('flex justify-between items-center border-2 rounded-md py-1 pl-4 pr-1', {
                                'border-levelSpecialist': i === 0,
                                'border-levelGold': i === 1,
                                'border-levelSilver': i === 2,
                                'border-levelSelected': i === 3
                            })}>
                                <span className={clsx('uppercase', {
                                    'text-levelSpecialist': i === 0,
                                    'text-levelGold': i === 1,
                                    'text-levelSilver': i === 2,
                                    'text-levelSelected': i === 3
                                })}>{t(item.label)}</span>
                                <span className={clsx('text-white font-bold text-lg rounded py px-4', {
                                    'bg-levelSpecialist': i === 0,
                                    'bg-levelGold': i === 1,
                                    'bg-levelSilver': i === 2,
                                    'bg-levelSelected': i === 3
                                })}>x {item.value}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='content-block'>
                    <h3 className="content-h3">{t('loyaltyInfo4Title')}</h3>
                    <p className='content-p'>{t('loyaltyInfo4Text')}</p>
                    <ul className="content-ul">
                        {laresPoints.map(item => (
                            <li key={item.model} className='flex justify-between py-2 border-b border-gray-200'><span>lares 4.0 <span className='text-ksenia'>{item.model}</span></span><span>{item.points} {t('points')}</span></li>
                        ))}
                    </ul>
                </div>
                <div className='content-block'>
                    <h3 className="content-h3">{t('loyaltyInfo5Title')}</h3>
                    <p className='content-p'>{t('loyaltyInfo5Text')}</p>
                </div>
                {!mustRemove ? (
                    <>
                        <div className='content-block'>
                            <h3 className="content-h3">{t('loyaltyInfo6Title')}</h3>
                            <p className='content-p'>{t('loyaltyInfo6Text')}</p>
                        </div>
                        <div className='content-block'>
                            <h3 className="content-h3">{t('loyaltyInfo7Title')}</h3>
                            <p className='content-p'>{t('loyaltyInfo7Text1')} <span className='font-bold'>{t('loyaltyInfo7Text2')}</span></p>
                        </div>
                        <div className='content-block'>
                            <h3 className="content-h3 text-brand">{t('loyaltyInfo8Title')}</h3>
                            <ul className='content-ul'>
                                {rankPrizes.overall.map(item => (
                                    <li key={item.position} className='flex items-center justify-between py-2 border-b border-gray-200'>
                                        <span>#{item.position} {t('rank')} </span>
                                        <span className='flex items-center'>
                                            <span className='text-brand'>{item.prizeDescription}</span>
                                            {item.thumb && (
                                                <img aria-hidden className={clsx('w-12 h-12 object-cover rounded-full border ml-2', {
                                                    'border-[#FEE101]': item.position === 1,
                                                    'border-[#A7A7AD]': item.position === 2,
                                                    'border-[#A77044]': item.position === 3
                                                })} src={item.thumb} alt={item.prizeDescription} />
                                            )}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='content-block'>
                            <h3 className="content-h3 text-brand">{t('loyaltyInfo9Title')}</h3>
                            <ul className='content-ul'>
                                {[...rankPrizes.specialist, ...rankPrizes.gold, ...rankPrizes.silver, ...rankPrizes.selected].map((item, i) => (
                                    <li key={item.prizeDescription} className='flex items-center justify-between py-2 border-b border-gray-200'>
                                        <span>#{item.position} {t('rank')} <span className={clsx('uppercase', {
                                            'text-levelSpecialist': i === 0,
                                            'text-levelGold': i === 1,
                                            'text-levelSilver': i === 2,
                                            'text-levelSelected': i === 3
                                        })}>{t(levelMultiplier[i].label)}</span></span>
                                        <span className='flex items-center'>
                                            <span className='text-brand'>{item.prizeDescription}</span>
                                            {item.thumb && (
                                                <img aria-hidden className={clsx('w-12 h-12 object-cover rounded-full border ml-2', {
                                                    'border-[#FEE101]': item.position === 1,
                                                    'border-[#A7A7AD]': item.position === 2,
                                                    'border-[#A77044]': item.position === 3
                                                })} src={item.thumb} alt={item.prizeDescription} />
                                            )}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='content-block'>
                            <h3 className="content-h3">{t('loyaltyInfo10Title')}</h3>
                            <p className='content-p'>{t('loyaltyInfo10Text')}</p>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    )
}

export default LoyaltyInfo