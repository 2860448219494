import React, { useEffect, useState } from 'react'
import { Panel } from './types'
import { State } from '../configureStore'
import { CompanyRole, getCompanyRole, hasACompany as hasACompanySelector, hasAdminRole } from '../profile/selectors'
import { connect } from 'react-redux'
import { PanelsList } from './PanelsList'
import { useTranslation } from 'react-i18next'
import { ModalDialog } from '../common/ModalDialog'
import { Panel as PanelDetail } from './Panel'
import Datamatrix from '../common/Datamatrix/Datamatrix'
import { usePanel } from '../hooks/usePanels'
import { Button, Obfuscate } from '../common'
import { Link } from 'react-router-dom'

interface ElevenListProps {
    selectedPanel?: Panel
    hasAdminRole: boolean
    companyRole: CompanyRole,
    hasACompany: boolean
}

type QrCodeData = {
    name?: string
    serialNumber?: string
}
export function ElevenList(props: ElevenListProps) {
    const { hasAdminRole, companyRole, hasACompany } = props
    const { t } = useTranslation()

    const [qrCodePanelDetail, setQrCodePanelDetail] = useState({} as QrCodeData)

    const [selectedPanel, setSelectedPanel] = useState<Panel | null>(null)
    const [mode, setMode] = useState<'notvisible' | 'edit' | 'add'>('notvisible')

    useEffect(() => {
        if (mode === 'notvisible')
            setSelectedPanel(null)
    }, [mode, setSelectedPanel])
    const modalOpen = mode !== 'notvisible'

    let modalTitle = ''
    if (mode === 'edit')
        modalTitle = t('panelsEditTitle')
    else if (mode === 'add')
        modalTitle = t('panelsAddTitle')

    const map = true
    const updateStateFilter = true
    const propagateAuth = true

    const {
        createPanel,
        updatePanel,
        deletePanel,
    } = usePanel()

    return (
        <Obfuscate
            show={companyRole === CompanyRole.Visitor}
            title={t('panelsVisitorsNeedCompanyInfoTitle')}
            subtitle={!hasACompany ? t('panelsVisitorsNeedCompanyInfoSubtitle') : undefined}
            action={!hasACompany ? <Button as={Link} text={t('panelsVisitorsNeedCompanyAction')} size="xl" color="primary" to='/createCompany' /> : undefined}
        >
            <h2 className="title-page">{t('panels')}</h2>

            <PanelsList
                featuresControl={{ map, updateStateFilter, propagateAuth }}
                onEdit={(panel) => { setSelectedPanel(panel); setMode('edit') }}
                onAdd={() => { setMode('add') }}
                onDisplayQrCode={(name, serialNumber) => {
                    setQrCodePanelDetail({ name, serialNumber })
                }}
            />
            <Datamatrix
                show={!!qrCodePanelDetail?.serialNumber}
                title={qrCodePanelDetail?.name}
                serialNumber={qrCodePanelDetail?.serialNumber || ''}
                onClose={() => setQrCodePanelDetail({})}
            />
            <ModalDialog
                open={modalOpen}
                onClose={() => { setMode('notvisible') }}
                title={modalTitle}
            >
                <PanelDetail
                    open={modalOpen}
                    mode={mode}
                    canEdit={true}
                    canDelete={hasAdminRole}
                    map={map}
                    {...(selectedPanel || {}) as Panel}
                    onCancel={() => { setMode('notvisible') }}
                    onSave={(panel: Panel) => {
                        const { serialNumber, mac, name, description, location } = panel
                        switch (mode) {
                            case 'add':
                                createPanel({
                                    serialNumber,
                                    name,
                                    description,
                                    location
                                })
                                break;
                            case 'edit':
                                updatePanel({
                                    name,
                                    description,
                                    location,
                                    mac
                                })
                                break;
                        }
                        setMode('notvisible')
                    }}
                    onDelete={() => {
                        if (selectedPanel && selectedPanel.mac) {
                            deletePanel(selectedPanel.mac)
                        }
                        setMode('notvisible')
                    }}
                />
            </ModalDialog>
        </Obfuscate>
    )
}


export function mapStateToProps(state: State) {
    return {
        hasAdminRole: hasAdminRole(state),
        companyRole: getCompanyRole(state),
        hasACompany: hasACompanySelector(state)
    }
}

export default connect(mapStateToProps)(ElevenList)