import React, { PropsWithChildren, ReactElement } from 'react'
import clsx from 'clsx'

interface ObfuscateProps {
    title: string
    show?: boolean
    subtitle?: string
    action?: ReactElement
}

export const Obfuscate = (props: PropsWithChildren<ObfuscateProps>) => {
    const { children, title, subtitle, action, show = false } = props
    const style = createStyle({ show })

    const Action = React.isValidElement(action) ? action : null

    return (
        <div className={style.container}>
            {children}
            {show ? <div className={style.obfuscator}>
                <h4 className={style.title}>{title}</h4>
                {subtitle ? <p className={style.subtitle}>{subtitle}</p> : null}
                {Action}
            </div> : null}
        </div>
    )
}

const createStyle = ({ show }: { show: boolean }) => ({
    container: clsx('relative flex flex-col min-h-screen', {
        'overflow-hidden relative h-screen': show
    }),
    obfuscator: 'flex flex-col justify-center items-center absolute inset-0 z-50 bg-white bg-opacity-90 fx-glass h-screen',
    title: 'text-center mb-2 text-gray-700',
    subtitle: 'mb-8 text-gray-500'
})