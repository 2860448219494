import React from 'react'
import clsx from 'clsx'
import { EmojiHappyIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Card from '../common/Card/Card'
import { RankWithPrize } from './actions'
import { rankPrizes } from './utils'

interface LeaderboardProps {
    title: string
    pending: boolean
    rankings?: RankWithPrize[]
    level?: 'overall' | 'specialist' | 'gold' | 'silver' | 'selected'
}

function prizeThumbBorder(i: number) {
    return clsx('w-16 h-16 object-cover rounded-full border-2', {
        'border-[#FEE101]': i === 0,
        'border-[#A7A7AD]': i === 1,
        'border-[#A77044]': i === 2
    })
}

const Leaderboard: React.VFC<LeaderboardProps> = (props) => {
    const { rankings = [], title, pending, level } = props
    const { t } = useTranslation()

    const missingRanksToFillLeaderboard = _.difference([0,1,2], _.range(0, rankings.length))

    const levelColorClass = clsx('uppercase text-brand font-bold', {
        'text-levelSelected': level === 'selected',
        'text-levelSilver': level === 'silver',
        'text-levelGold': level === 'gold',
        'text-levelSpecialist': level === 'specialist'
    })

    return (
        <div>
            <Card pending={pending}>
                <Card.Header>
                    <span className={levelColorClass}>{title}</span>
                </Card.Header>
                <Card.Body full>
                    <ul className='divide-y divide-gray-100'>
                        {rankings && rankings.map((rank, i) => (
                            <li key={rank.position} className={clsx('flex items-center space-x-4 px-6 py-4', {
                                'bg-gray-50': rank.position > 3
                            })}>
                                <span className='text-brand'>{`#${rank.position}`}</span>
                                {rank.thumb && (
                                    <img aria-hidden className={prizeThumbBorder(i)} src={rank.thumb} alt={rank.prize} />
                                )}
                                <div className='flex flex-col'>
                                    <span>{rank.points} {t('points')}</span>
                                    <span className='text-gray-500'>{rank.prize}</span>
                                </div>
                                {rank.you && (
                                    <span className='motion-safe:animate-pulse flex items-center !ml-[auto] px-4 py-1 rounded-full bg-brand text-white'>
                                        <EmojiHappyIcon className='w-5 h-5 mr-2' />
                                        {t('you')}
                                    </span>
                                )}
                            </li>
                        ))}

                        {missingRanksToFillLeaderboard.map((i) => {
                            return (
                                <li key={`missing-rank-${i}`} className="flex items-center space-x-4 px-6 py-4">
                                    <span className='text-brand h-16 border-2 border-transparent flex items-center'>{`#${i+1}`}</span>
                                    {level && rankPrizes[level][i] && rankPrizes[level][i].thumb ? <img
                                        aria-hidden
                                        src={rankPrizes[level][i].thumb}
                                        className={prizeThumbBorder(i)}
                                        alt={rankPrizes[level][i].prizeDescription} /> : null
                                    }
                                    <div className='flex flex-col'>
                                        {level && rankPrizes[level][i] ? <span>{rankPrizes[level][i].prizeDescription}</span> : null}
                                        <span className='text-gray-500'>{t('vacantLeaderboardPlace')}</span>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Leaderboard