
const isPrizeDefined = new Date().getUTCFullYear() < 2025
const rankPrizesImages = (level: string) => {
    if (!isPrizeDefined)
        return

    const rank: { [key: string]: any } = {
        overall1: require('../images/loyalty/overall1.png').default,
        overall2: require('../images/loyalty/overall2.png').default,
        overall3: require('../images/loyalty/overall3.png').default,
        selected: require('../images/loyalty/selected1.png').default,
        silver: require('../images/loyalty/silver1.png').default,
        gold: require('../images/loyalty/gold1.png').default,
        specialist: require('../images/loyalty/specialist1.png').default
    }

    return rank[level]
}

const rankPrizesDescription = (level: string) => {
    if (!isPrizeDefined)
        return

    const rank: { [key: string]: any } = {
        overall1: 'MacBook Air M1',
        overall2: 'Apple iPhone 14 Pro',
        overall3: 'DJI Mini 3',
        selected: 'Marshall Emberton',
        silver: 'Dyson Pure Hot+Cool',
        gold: 'Rayban Smart Glass',
        specialist: 'Xiaomi Scooter Pro 2'
    }

    return rank[level]
}

export const rankPrizes: { [key: string]: Array<{ position: number, prizeDescription: string, thumb: string }>; } = {
    'overall': [
        { position: 1, prizeDescription: rankPrizesDescription('overall1'), thumb: rankPrizesImages('overall1') },
        { position: 2, prizeDescription: rankPrizesDescription('overall2'), thumb: rankPrizesImages('overall2') },
        { position: 3, prizeDescription: rankPrizesDescription('overall3'), thumb: rankPrizesImages('overall3') }
    ],
    'specialist': [{
        position: 1,
        prizeDescription: rankPrizesDescription('specialist'),
        thumb: rankPrizesImages('specialist')
    }],
    'gold': [{ position: 1, prizeDescription: rankPrizesDescription('gold'), thumb: rankPrizesImages('gold') }],
    'silver': [{ position: 1, prizeDescription: rankPrizesDescription('silver'), thumb: rankPrizesImages('silver') }],
    'selected': [{
        position: 1,
        prizeDescription: rankPrizesDescription('selected'),
        thumb: rankPrizesImages('selected')
    }]
}

export const laresPoints = [
    { model: '16', points: 20 },
    { model: '96 wls', points: 40 },
    { model: '40', points: 40 },
    { model: '40 wls', points: 50 },
    { model: '140 wls', points: 100 },
    { model: '644 wls', points: 200 }
]