import React, { useState } from 'react'
import { Menu } from './Menu/Menu'
import { State } from '../configureStore'
import { connect } from 'react-redux'
import { MenuIcon } from '@heroicons/react/outline'
import { getApplicationRoles, getUserCompanyProfile, ApplicationRole, UserCompanyProfile, getCompanyRole, companyType, CompanyType, CompanyRole } from '../profile/selectors'
import { getReleaseNotesUrl } from './utils'


interface Props {
    releaseNotesUrl: string
    applicationRoles: ApplicationRole[]
    companyRole: CompanyRole
    companyType: CompanyType
    user?: UserCompanyProfile
}

export function Layout(props: React.PropsWithChildren<Props>) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { user, applicationRoles, companyRole, companyType } = props

    return <div className="h-screen flex overflow-hidden">
        {/** Sidebar */}
        <Menu
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            user={user}
            companyType={companyType}
            releaseNotesUrl={props.releaseNotesUrl}
            companyRole={companyRole}
            applicationRoles={applicationRoles}
        />
        {/* Content */}
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="lg:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-800">
                <button
                    className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="flex-1 overflow-auto focus:outline-none">
                {/* Main Content */}
                <div className='flex-1 overflow-hidden min-h-full bg-gray-50 relative'>
                    {props.children}
                </div>
                {/* <Footer /> */}
            </div>
        </div>
    </div>
}

export function mapStateToProps(state: State) {
    return {
        user: getUserCompanyProfile(state) as UserCompanyProfile,
        applicationRoles: getApplicationRoles(state),
        companyType: companyType(state),
        companyRole: getCompanyRole(state),
        releaseNotesUrl: getReleaseNotesUrl()
    }
}

export default connect(mapStateToProps)(Layout)